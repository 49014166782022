import { permissions } from '@mpk/shared/domain';

enum PlatformSuppliersPermissionsEnum {
  None = 0,

  /**
   * View platform suppliers.
   */
  ViewPlatformSuppliers = 1,
  /**
   * View and manage platform suppliers.
   */
  ManagePlatformSuppliers = 2 | ViewPlatformSuppliers,
}

export const PlatformSuppliersPermissions = permissions(PlatformSuppliersPermissionsEnum, {
  featureId: 'PlatformSuppliers',
});
