import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { filterUndefined, getProp } from '@core/shared/util';
import { PagedDataSource } from '@core/ui';
import { PlatformSupplierBasic } from '@mp/supplier-management/common/domain';
import { PlatformSupplierFacade } from '@mp/supplier-management/platform-suppliers/data-access';
import { PlatformSupplierListComponent } from '@mp/supplier-management/platform-suppliers/ui';

type FetchParams = { searchTerm: string };

@Component({
  selector: 'mpsm-platform-suppliers-overview',
  standalone: true,
  templateUrl: './platform-suppliers-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, PlatformSupplierListComponent],
})
export class PlatformSuppliersOverviewComponent {
  readonly dataSource: PagedDataSource<PlatformSupplierBasic, FetchParams> = this.facade.dataSource;
  readonly resultCount$: Observable<number> = this.facade.pagination$.pipe(filterUndefined(), getProp('totalRowCount'));

  readonly isProcessingExport$: Observable<boolean> = this.facade.isProcessingExport$;

  constructor(private readonly facade: PlatformSupplierFacade) {
    this.handleSearch('');
  }

  handleSearch(searchTerm: string): void {
    this.dataSource.updateQueryParams({ searchTerm });
  }

  exportAll(): void {
    this.facade.exportAll();
  }
}
